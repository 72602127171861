import React from "react";
import logo from "../images/fmm-logo-black@2x.png"
import successGif from "../images/topgun.gif"
import sendLogo from "../images/the-send-logo-black@2x.png"
import emblems from "../images/fmm-take-action-emblem-black@2x.png"



export default function SuccessAreanas() {


    return (
      <div className="page page-success">
            <div className="container content" >
            <div className="row">
                  <div className="col main">
                        <div className="section">
                              <img className="logo" src={logo}></img>
                              
                              <h1 className="success-title uppercase">You Did it!</h1>
                              <p className="success-p">
                              You are now one step closer to the nations!
                              <br /><br />
                              <img className="success-video" src={successGif}></img>
                              Here’s what happens next… In the next few days you’ll receive a personal phone call from our team helping you take the next step and answering any questions you may have.<br /><br />

                              Enjoy the rest of The Send!<br /><br />
                              </p>
                              
                              
                              {/* <video id="success-video" className="success-video" poster="" width="320" height="240" playsInline autoPlay muted loop>
                                    <source src={successVideo} type="video/mp4"/>
                                    Your browser does not support the video tag.
                              </video> */}
                              <div className="footer-row">
                                    <div className="col-left"><img src={sendLogo} alt="The Send"/></div>
                                    <div className="col-right"><img className="emblems" src={emblems} alt="Take Action"/></div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
    </div>
    );
  }