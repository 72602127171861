import React from "react";

export default function({community}){

    const banner = community.attributes?.banner.data?.attributes?.url
    const focalPoint = community.attributes.horizontalFocalPoint
    let imageURL

    if(banner){
        imageURL = banner;
        if (community.attributes.banner.data?.attributes.formats?.thumbnail.url){
            imageURL = community.attributes.banner.data.attributes.formats.thumbnail.url
        }
        if ( !imageURL.startsWith("http") ){ imageURL = process.env.REACT_APP_BACKEND + imageURL }
    }
    
    return(
        <div className="community__image-bg">
            {imageURL && <img src={imageURL} style={{objectPosition: focalPoint + '% center'}} /> }
        </div>
    )
}