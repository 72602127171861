import React from "react"
import './App.css';
import Communities from './components/Communities';
import CommunitySkeleton from "./components/CommunitySkeleton"


import SingleCommunity from "./pages/SingleCommunity";
import Success from "./pages/Success";
import SuccessArenas from "./pages/SuccessArenas";


import { Router, Route } from "wouter";
import ScrollToTop from "./utils/ScrollToTop"; //moves to the top of the page when a new link is clicked in wouter.

import logo from "./images/fmm-logo-black@2x.png"
import sendLogo from "./images/the-send-logo-black@2x.png"
import asideLogosInner from "./images/fmm-aside-logos-inner@2x.png"

import GoAnywhereCard from "./components/GoAnywhereCard";

const url_string = window.location.href;
const url = new URL(url_string);
const emailVar = url.searchParams.get("email");
const userEmail = emailVar ? emailVar : 'billing@cogentstudios.com';

const submit_strapi = async (email) => {
  console.log("email: " + email);
  // const config = { // important!
  //       headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `bearer ${process.env.REACT_APP_STRAPI_SUBMISSION_TOKEN}`,//Required for authorized requests from a server (can't be used from a browser).
  //       },
  // }

  // const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/submissions`,
  // {
  //       "data": {
  //             "email": email,
  //             "hubspotId": hubspotId,
  //             "synced": false,
  //             "missions_community": id ,
  //       }
  // },
  // config
  // ).then(function(response){
  // const status = response.status;
  // if (status == 200){
  //       window.location = "/success"
  // }
  // });
  
  // return response;
}


function App() {
  const [loading, setLoading] = React.useState(false);
  const [posts, setPosts] = React.useState([])

    const fetchPosts = () => {
      try {
        setLoading(true)
        
        // fetch the least cycled communites, showing the oldest first.
        fetch(`${process.env.REACT_APP_BACKEND}/api/communities`)
        .then((response) => response.json() )
        .then((data) => {
          setPosts(data.data)
          
          setLoading(false)
        })
        console.log(posts)
      } catch (error) {
        //setLoading(false); //Add this back once you set up an error display message. Like 'Oops... Something went wrong. Please check back soon or reach out to us at...'
        console.log(error);
      }
        
      
    }

    React.useEffect(() => {
        fetchPosts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //console.log(posts)
    

  return (
    <div className="App theme--light">
      <link rel="stylesheet" href="https://use.typekit.net/pej1qjg.css" />
      <Router>
        <ScrollToTop />
      <Route path="/choose/:id">
      
      {(params) =>
        <SingleCommunity id={params.id} communities={posts} email={userEmail} submitStrapi={submit_strapi} />
      }
      </Route>

      <Route path="/success">
        <Success />
      </Route>

      <Route path="/arenas-success">
        <SuccessArenas />
      </Route>

      <Route path="/"> {/* Homepage */}
          <div className="page page-communities">
            <div className="container">
              <div className="home-main-row">
                <div className="col aside hide-below-desktop">
                  <div className="background-container aside-content">
                    <div className="home-aside-text">A GENERATION WAITS— <br/>WHAT’S YOUR RESPONSE?</div>
                    <img className="aside-logos-inner" src={asideLogosInner}></img>
                  </div>
                </div>
                <div className="col main">
                  <div className="section content">
                    <header className="App-header">
                      <div className="logos-wrapper">
                        <img className="logo" alt="Find My Mission" src={logo}></img>
                        <div className="powered-by-logo-wrapper"><div className="powered-by-text">Powered by</div><img className="send-logo" src={sendLogo} alt="The Send" /></div>
                      </div>
                      
                      <h3 className="welcome-title uppercase">Last Step...<br/> Where will you go?</h3>
                      <p className="welcome-p">Here are the top 3 communities we’d recommend for you. Tap an experience to learn more and choose an experience…</p>
                    </header>
                    {loading ? <CommunitySkeleton numberOfCards={3} /> :<Communities communities={posts} email={userEmail} />}
                    <GoAnywhereCard email={userEmail} successUrl={'/success'} />                    
                  </div>
                </div>
              </div>
          </div>
        </div>
      </Route>
      <Route path="/arenas"> {/* Form for arenas... */}
          <div className="page page-communities">
            <div className="container">
              <div className="home-main-row">
                <div className="col aside hide-below-desktop">
                  <div className="background-container aside-content">
                    <div>A GENERATION WAITS— <br/>WHAT’S YOUR RESPONSE?</div>
                    <img className="aside-logos-inner" src={asideLogosInner}></img>
                  </div>
                </div>
                <div className="col main">
                  <div className="section content">
                    <header className="App-header">
                      <div className="logos-wrapper">
                        <img className="logo" alt="Find My Mission" src={logo}></img>
                        <div className="powered-by-logo-wrapper"><div className="powered-by-text">Powered by</div><img className="send-logo" src={sendLogo} alt="The Send" /></div>
                      </div>
                      <h3 className="welcome-title uppercase">Last Step...<br/> Where will you go?</h3>
                      <p className="welcome-p">Here are the top 3 communities we’d recommend for you. Choose one to get connected with their team...</p>
                    </header>
                    {loading ? <CommunitySkeleton numberOfCards={3} /> :<Communities communities={posts} email={userEmail} unified={true} />}
                    <GoAnywhereCard email={userEmail} successUrl={'/arenas-success'} />
                    {/* <SendMeButton btnText={'Send Me Anywhere!'} email={userEmail} hubspotId={'anywhere'} successUrl={'/arenas-success'} /> */}
                  </div>
                </div>
              </div>
          </div>
        </div>
      </Route>
      </Router>


      
    </div>
  );
}

export default App;
