import React from "react";

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }


export default function({bucket, community}){

    const bucketMax = 100; // The number to display as a full bucket.
    const minDisplay = 20; // Show at least this many applicants for each community.
    const randomOffset = randomIntFromInterval(0, 20); // The Offset makes it so not every community has exactly 25 applicants at first.
    const maxTicks = 3; // the number of times a community should have a live display increase.

    // Attempt to load number of ticks from local storage.
    let ticked = Number( window.localStorage.getItem(community.slug+'-ticked') );

    // Pick an initial random time.
    let randomTime = randomIntFromInterval(3,15) 

    //Set up local storage.
    const localStorageKey = community.slug + '-display-number'
    let localBucket = Number(localStorage.getItem(localStorageKey))

    //Update local storage if real bucket is larger.
    // (Doing this in reverse below.)
    // if ( bucket > localBucket) {
    //     localBucket = bucket;
    // }


    //Check for local bucket and use that if it's higher than real bucket.
    if ( localBucket > bucket ) {bucket = localBucket}

    //If the bucket hasn't reached min display number yet, use display number instead.
    if (bucket < minDisplay){
        bucket = minDisplay + randomOffset; // Add the min display amount and a random number to the bucket.
        window.localStorage.setItem(localStorageKey, bucket) //Save the fake number to browser so it stays consistent.
    } 

    function tickUpDisplay(){
        const parent = document.getElementById('community-'+community.slug);
        var progressBar = parent.querySelector('.filled');
        var applicantsNumber = parent.querySelector('.applicants-number')
        
        // This is a very non "React" way of handeling this, Im doing with explicit javascript instead. I should come back and refactor as a real React component later.
        window.localStorage.setItem(community.slug+'-ticked',ticked+1) //increase ticks
        bucket++
        window.localStorage.setItem(localStorageKey, bucket) //increase localBucket
        //increase width style
        progressBar.style.width = bucket+'%';
        //increase counter 
        applicantsNumber.textContent = bucket;
    }
    
    function randomlyTickUp(){        
        setTimeout(() => {
            // An amount of seconds between these numbers to count before ticking up.
            randomTime = randomIntFromInterval(3, 20); 
            
            // recheck the local storage.
            ticked = Number( window.localStorage.getItem(community.slug+'-ticked') ); 

            // If we haven't ticked up x times, and the bucket isn't full...
            if (ticked < maxTicks && bucket < bucketMax ){
                tickUpDisplay(); // Tick up display
                randomlyTickUp(); //recurslively call this function.
            }
        }, randomTime * 1000);

    }
    randomlyTickUp(); //Call it the first time.

    
    
    return(
        <div className="bucket-wrapper">
                    <div className="applicants">
                        <span>Spots Filled:</span>
                        <span><span className="applicants-number">{bucket}</span><span>/{bucketMax}</span></span>
                    </div>
                    <div className="progress-bar">
                        <span style={{width:`${bucket}%`}} className="filled"></span>
                    </div>
                </div>
    )
}