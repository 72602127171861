import React from "react";

import { Link } from "wouter";
import ApplicantsCounter from "./ApplicantsCounter"
import CommunityMeta from "./CommunityMeta";
import CardImage from "./CardImage"
  

export default function Community({community, name,desc,strapiCommunityID,bucket}){

    const affiliation = community.attributes.affiliation;
        
    return(
        <Link href={`/choose/${strapiCommunityID}`}>
        <a className="link">
        <article id={'community-'+community.attributes.slug} className={`community-card`} key={strapiCommunityID}>
            <div className="community__image">
                <CardImage community={community} />
                <div className="name-affiation-wrapper mobile-only">
                    <div className="name-wrapper">
                        <div className="com-name-label com-label">
                            Missional Community:
                        </div>
                        <div className="community__name">{name}</div>
                    </div>
                    {affiliation && 
                    <div className="affiliation-wrapper">
                        <div className="com-affiliation-label com-label">
                            Affiliation:
                        </div>
                        <div className="community__affiliation">{affiliation}</div>
                    </div>
                    }
                </div>
            </div>
            <div className="community__info">
            <div className="name-affiation-wrapper hide-below-tablet">
                    <div className="name-wrapper">
                        <div className="com-name-label com-label">
                            Missional Community:
                        </div>
                        <div className="community__name">{name}</div>
                    </div>
                    {affiliation && 
                    <div className="affiliation-wrapper">
                        <div className="com-affiliation-label com-label">
                            Affiliation:
                        </div>
                        <div className="community__affiliation">{affiliation}</div>
                    </div>
                    }
                </div>
                
                <div className="community__desc">{desc}</div>
                <CommunityMeta community={community.attributes} />
                <ApplicantsCounter bucket={bucket} community={community.attributes} />
            </div>
            <div className="go-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="28.508" height="20.978" viewBox="0 0 28.508 20.978">
                    <g id="Group_10" data-name="Group 10" transform="translate(1 1.412)">
                        <line id="Line_1" data-name="Line 1" x2="26.096" transform="translate(0 9.077)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
                        <line id="Line_2" data-name="Line 2" x2="10.212" y2="9.077" transform="translate(15.885)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
                        <line id="Line_3" data-name="Line 3" y1="9.077" x2="10.212" transform="translate(15.885 9.077)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
                    </g>
                </svg>
            </div>
        </article>
        </a>
        </Link>
    )
}