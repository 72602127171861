import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const CardSkeleton = ({numberOfCards}) => {
    return (
        Array(numberOfCards).fill(0).map((_, index) => (
            <div className="community-card community-card-skeleton" key={index}>
                <div className="skeleton__image-placeholder"></div>
                <div className="skeleton__content-wrapper">
                    <Skeleton height={12} width={"40%"} style={{marginBottom:15}} />
                    <Skeleton className="skeleton__desc" count={3} height={8} />
                    <div className="skeleton__meta-wrapper">
                        <Skeleton height={15} />
                        <Skeleton height={15} />
                        <Skeleton height={15} />
                        <Skeleton height={15} />
                        <Skeleton height={15} />
                        <Skeleton height={15} />
                    </div>
                    <Skeleton height={20} />
                </div>
                
            </div>
        )
    )
    )
}

export default CardSkeleton;