import React from "react"
import Community from "./Community"
import CommunityUnified from "./CommunityUnified"
import GoAnywhereCard from "./GoAnywhereCard"


export default function Communities({communities, email, unified}){
    console.log(communities);
    function listCommunities(){

        const communityElements = communities.map( community => {
            if (unified){
                return (
                    <CommunityUnified 
                        community = {community}
                        email={email} //Passed from parent.
                        key={community.id}
                        strapiCommunityID={community.id}
                        name={community.attributes.title} 
                        desc={community.attributes.description} 
                        banner={community.attributes?.banner.data?.attributes?.url}
                        hubspotId={community.attributes.slug}
                        bucket={community.attributes.bucket}
                        trainingLocation={community.attributes.training_location}
                        missionsFocus={community.attributes.missions_focus}
                        baseSize={community.attributes.base_size}
                        semesters={community.attributes.semesters}
                        id={community.id}
                    />
                )
            }else{
                return (
                    <Community 
                        community = {community}
                        email={email} //Passed from parent.
                        key={community.id}
                        strapiCommunityID={community.id}
                        name={community.attributes.title} 
                        desc={community.attributes.description}
                        hubspotId={community.attributes.slug}
                        bucket={community.attributes.bucket}
                        trainingLocation={community.attributes.training_location}
                        missionsFocus={community.attributes.missions_focus}
                        baseSize={community.attributes.base_size}
                        semesters={community.attributes.semesters}
                    />
                    
                )
            }
            
        })
        
    
        return communityElements
    }


    return(
        <div className="communities-wrapper">
            
            {listCommunities()}

        </div>
    )
}