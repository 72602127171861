import React from "react";
import axios from 'axios';

export default function SendMeButton({btnText,email,hubspotId,id,successUrl}) {

    const successLink = successUrl ? successUrl : '/success'

    //Submit form to hubspot.
    const submit_strapi = async (e) => {
        e.currentTarget.classList.add("loading");
        
        const config = { // important!
              headers: {
              'Content-Type': 'application/json',
              'Authorization': `bearer ${process.env.REACT_APP_STRAPI_SUBMISSION_TOKEN}`,//Required for authorized requests from a server (can't be used from a browser).
              },
        }

        const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/submissions`,
        {
              "data": {
                    "email": email,
                    "hubspotId": hubspotId,
                    "synced": false,
                    "missions_community": id ,
              }
        },
        config
        ).then(function(response){
        const status = response.status;
        if (status == 200){
              window.location = successLink;
        } else {
            e.currentTarget.classList.remove("loading");
        }
        });
        
        return response;
  }

  return(
    <button className="send-me-btn" onClick={submit_strapi}>
            <div className="btn-content" >
                {btnText}
                <span className="btn-icon">
                <svg viewBox="-1.2 -1.1 28.5 21.1" xmlns="http://www.w3.org/2000/svg" stroke="#fff" strokeLinecap="round" strokeWidth="2" fill="none">
                        <line transform="translate(0 9.077)" x2="26.096"/>
                        <line transform="translate(15.885)" x2="10.212" y2="9.077"/>
                        <line transform="translate(15.885 9.077)" x2="10.212" y1="9.077"/>
                </svg>


                </span>
            </div>
            <div  className="btn-loading">
                <span className="loader"></span>
            </div>
    </button>
    )
}

