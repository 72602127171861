import React from "react";
import SendMeButton from "./SendMeButton";

export default function({email}){    
    return(
        <article className="community-card go-anywhere-card">
            <div className="go-anywhere__title">WILLING TO GO ANYWHERE?</div>
            <div className="go-anywhere__desc">Click this button and we will match you with an urgent need somewhere around the world...</div>
            <SendMeButton btnText={'I\'ll go anywhere'} email={email} hubspotId={'anywhere'} />
        </article>
    )
}